import { useEffect, useState } from "react";
import Router from "./router";
import { Box, AppBar, Drawer, Toolbar, Link, IconButton, MenuItem } from "@mui/material";
import Logo from "./assets/images/icons/logo.svg";
import LogoSmall from "./assets/images/icons/logo-small.webp";
import BurgerIcon from "./assets/images/icons/burger-icon.svg";
import CardLoader from "./assets/card-loader.gif";
import axios from "axios";

const App = () => {
  const VALID = "valid";

  const [loadingTokenValidation, setLoadingTokenValidation] = useState(true);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const validateToken = async () => {
    setLoadingTokenValidation(true);
    const orderId = sessionStorage.getItem("order_id");

    const data = {
      action: "validate_order",
      order_id: orderId,
      product: "card",
    };
    try {
      const response = await axios.post("https://hook.us1.make.com/3xohab79olu1j8kd0r1gdqiwcwoqctpw", data);
      console.log(data);
      if (response.data !== VALID) {
        window.top.location.href = `https://anekdamian.com/card-designer/?reason=invalid`;
      } else {
        setLoadingTokenValidation(false);
      }
    } catch (err) {
      window.top.location.href = `https://anekdamian.com/card-designer/?reason=invalid`;
      console.error(err);
    }
  };

  const handleTokenSet = () => {
    const params = new URLSearchParams(window.location.search);
    const orderId = params.get("order_id");

    if (orderId) {
      sessionStorage.setItem("order_id", orderId);
    } else {
      sessionStorage.removeItem("order_id");
    }
  };

  const toggleDrawer = (open) => {
    setIsOpenDrawer(open);
  };

  useEffect(() => {
    let timer;
    console.log("1.35");
    handleTokenSet();
    validateToken();
    timer = setTimeout(() => {
      sessionStorage.removeItem("order_id");
      validateToken();
    }, 1800000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (loadingTokenValidation) {
    return (
      <Box id="CharacterCreator">
        <Box sx={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img src={CardLoader} style={{ width: "195px", height: '195px' }} alt="card_loader" />
        </Box>
      </Box>
    );
  }

  return (
    <div className="app">
      <AppBar position="static" sx={{ backgroundColor: "black", paddingX: "10px" }}>
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <img src={Logo} style={{ height: "40px" }} alt="logo" />
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <img src={LogoSmall} style={{ height: "40px" }} alt="logo" />
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Link sx={{ paddingX: "20px", color: "#FFFFFF", textDecoration: "none" }} href="https://anekdamian.com/">
              Home
            </Link>
            <Link sx={{ paddingX: "20px", color: "#FFFFFF", textDecoration: "none" }} href="https://anekdamian.com/">
              1️⃣ Buy a Custom Card
            </Link>
            <Link sx={{ paddingX: "20px", color: "#FFFFFF", textDecoration: "none" }} href="https://anekdamian.com/card-designer/">
              2️⃣ Design My Card
            </Link>
            <Link sx={{ paddingX: "20px", color: "#FFFFFF", textDecoration: "none" }} href="https://anekdamian.com/my-account/">
              My account
            </Link>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={() => toggleDrawer(true)}>
              <img src={BurgerIcon} style={{ width: "22px", height: "22px" }} alt="burger" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        open={isOpenDrawer}
        anchor="top"
        sx={{
          marginTop: "56px",
          "& .MuiDrawer-paper": { marginTop: "56px" },
        }}
        onClose={() => toggleDrawer(false)}
      >
        <Box>
          <MenuItem sx={{ justifyContent: "center", borderBottomColor: "#33373d", borderStyle: "solid", borderBottomWidth: "1px" }}>
            <Link sx={{ paddingX: "20px", color: "#000000", textDecoration: "none" }} href="https://anekdamian.com/">
              Home
            </Link>
          </MenuItem>
          <MenuItem sx={{ justifyContent: "center", borderBottomColor: "#33373d", borderStyle: "solid", borderBottomWidth: "1px" }}>
            <Link sx={{ paddingX: "20px", color: "#000000", textDecoration: "none" }} href="https://anekdamian.com/">
              1️⃣ Buy a Custom Card
            </Link>
          </MenuItem>
          <MenuItem sx={{ justifyContent: "center", borderBottomColor: "#33373d", borderStyle: "solid", borderBottomWidth: "1px" }}>
            <Link sx={{ paddingX: "20px", color: "#000000", textDecoration: "none" }} href="https://anekdamian.com/card-designer/">
              2️⃣ Design My Card
            </Link>
          </MenuItem>
          <MenuItem sx={{ justifyContent: "center" }}>
            <Link sx={{ paddingX: "20px", color: "#000000", textDecoration: "none" }} href="https://anekdamian.com/my-account/">
              My account
            </Link>
          </MenuItem>
        </Box>
      </Drawer>
      <Router />
    </div>
  );
};

export default App;
