import { Suspense, lazy } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Box, Typography } from "@mui/material";

const SelectPose = lazy(() => import("./pages/SelectPose"));
const CardCustomizer = lazy(() => import("./pages/CardCustomizer"));
const Preview = lazy(() => import("./pages/Preview"));

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<SelectPose />} />
        <Route
          path="/card-customizer"
          element={
            <Suspense>
              <CardCustomizer />
            </Suspense>
          }
        />
        <Route
          path="/preview"
          element={
            <Suspense>
              <Preview />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
              <Typography sx={{ color: "#000000", textTransform: "uppercase" }}>Page not found</Typography>
            </Box>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
